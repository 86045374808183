<template>
  <!-- 实名认证 -->
  <div id="app">
    <div class="center-tit">
      实名认证
      <span>/Identity Certification</span>
    </div>
    <!-- 待审核 -->
    <div v-if="shStatus === 1" class="sh-status">
      <img src="@/assets/img/under-review.png" />
      <p>待审核...</p>
      <div>
        认证资料将在
        <span>8小时内</span>审核完成
      </div>
    </div>
    <!-- 已认证 -->
    <div v-else-if="shStatus === 2 || shStatus === 3" class="sh-status">
      <img src="@/assets/img/sh-success.png" />
      <div style="margin-top: 24px">您已完成实名认证审核</div>
    </div>
    <!-- 认证内容 -->
    <div v-else class="account-con">
      <div class="certificate-way">
        <div class="box-con">
          <div
            v-for="(item, index) in ways"
            :key="index"
            :class="item.checked ? 'active' : ''"
            class="box"
            @click="change(index)"
          >
            <img :src="item.icon" class="logo" />

            <div v-if="index == 0">
              <p>支付宝</p>
              <p>实名认证</p>
            </div>
            <div v-if="index == 1">
              <p>CSON官方</p>
              <p>实名认证</p>
            </div>
            <img
              v-if="item.checked"
              class="choosed"
              src="@/assets/img/pay-active-icon.png"
            />
          </div>
        </div>
        <div class="agree">
          <input v-model="checked" type="checkbox" />
          <label style="margin-left: 3px" @click="checked = true"
            >同意CSON官方用户协议认证</label
          >
        </div>
        <div class="btn">
          <button @click="submit">点击开始认证</button>
        </div>
      </div>
      <div class="certificate-tips">
        <ul>
          <li>
            1.CSON基于视频版权保护的需要，禁止录屏，观看CSON原创课程视频均需要提供并完成实名认证；
          </li>
          <li>
            2.视频观看时，个人邮箱（或电话）随机在视频不同位置显示，3s换位置跳动，发现录屏后，可以通过实名制找到录屏者，以法律进行解决
          </li>
          <li>3.CSON实名认证系统由阿里巴巴集团阿里云提供全程技术与服务；</li>
          <li>4.实名认证通过后，信息将无法修改和翻除，请谨慎填写；</li>
          <li>5.我们会确保你所提供的值均处于严格的保密状态，不会泄漏；</li>
          <li>
            6.如果多次认证不通过，请联系邮箱admin@cspiration.com，如果存在恶意乱写姓名，身份证号码及上传与身份证证件无关图片者，一经发现将冻结账户；
          </li>
          <li>
            7.如果存在恶意乱填写姓名，身份证号码及上传身份证证件无关图片者，一经发现将冻结CSON官网账号。
          </li>
        </ul>
      </div>
    </div>
    <!-- 支付宝认证步骤弹框 -->
    <div v-if="dialogFormVisible" class="submit-con">
      <div class="ali-box" style="padding: 25px 71px">
        <img
          class="close"
          src="@/assets/img/cartdelete-icon.png"
          @click="dialogFormVisible = false"
        />
        <div v-if="stepone" class="confirm-con step1">
          <div class="tit">
            <img src="@/assets/img/alipay.png" />支付宝实名认证
          </div>
          <div class="current">
            <ul>
              <li>
                <img src="@/assets/center/ali-step1.png" />
                <p class="cur">基本信息</p>
                <span></span>
              </li>
              <li>
                <img src="@/assets/center/ali-step2.png" />
                <p>人脸识别</p>
                <span></span>
              </li>
              <li>
                <img src="@/assets/center/ali-step3.png" />
                <p>认证成功</p>
              </li>
            </ul>
          </div>
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="中文名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input
                v-model="form.sid"
                onkeyup="value=value.replace(/[^\dXx]/g,'')"
              ></el-input>
            </el-form-item>
            <!-- <div class="paytips">
                          本资料授权阿里云是文案我是文案我是文案
                          我是文案我是文案我是文案我是文案
                        </div> -->
            <div style="text-align: center">
              <button class="next" @click="(e) => onInitAlipayCertification(e)">
                下一步
              </button>
            </div>
          </el-form>
        </div>
        <div v-if="steptwo" class="confirm-con step1">
          <div class="tit">
            <img src="@/assets/img/alipay.png" />支付宝实名认证
          </div>
          <div class="current">
            <ul>
              <li>
                <img src="@/assets/center/ali-step1.png" />
                <p class="cur">基本信息</p>
                <span class="cur"></span>
              </li>
              <li>
                <img src="@/assets/center/ali-step22.png" />
                <p class="cur">人脸识别</p>
                <span></span>
              </li>
              <li>
                <img src="@/assets/center/ali-step3.png" />
                <p>认证成功</p>
              </li>
            </ul>
          </div>
          <div class="alipay-code">
            <img :src="certImage" />
          </div>
          <div class="alipay-code-tip">
            <p>使用支付宝，扫码开始人脸识别</p>
          </div>
          <!-- <div style="text-align: center">
            <button class="next" @click="onQueryCertResult">下一步</button>
          </div> -->
        </div>
        <div v-if="stepthree" class="confirm-con step1">
          <div class="tit">
            <img src="@/assets/img/alipay.png" />支付宝实名认证
          </div>
          <div class="current">
            <ul>
              <li>
                <img src="@/assets/center/ali-step1.png" />
                <p class="cur">基本信息</p>
                <span class="cur"></span>
              </li>
              <li>
                <img src="@/assets/center/ali-step22.png" />
                <p class="cur">人脸识别</p>
                <span class="cur"></span>
              </li>
              <li>
                <img src="@/assets/center/ali-step33.png" />
                <p class="cur">认证成功</p>
              </li>
            </ul>
          </div>
          <div class="alipay-ok">
            <img src="@/assets/center/bingo-icon.png" />
          </div>
          <div class="alipay-code-tip">
            <p>实名认证完成！</p>
          </div>
          <div style="text-align: center">
            <button class="next" @click="done">完成</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 认证弹窗   6.17 -->
    <div v-if="cerVisible" class="submit-con">
      <div class="ali-box" style="padding: 25px 120px">
        <img
          class="close"
          src="@/assets/img/cartdelete-icon.png"
          @click="cerVisible = false"
        />
        <div class="confirm-con">
          <div class="cer-title">为了信息安全，请先完成手机号/邮箱绑定</div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="手机号验证" name="cphone">
              <el-form class="cer-form" ref="form" :model="cerPhoneform">
                <div class="cer-flex">
                  <el-select
                    class="cer-select"
                    v-model="countrysvalue"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in countrys"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    >
                      <div class="select-row">
                        <span class="label">{{ item.label }}</span>
                        <span class="value">{{ item.value }}</span>
                      </div>
                    </el-option>
                  </el-select>
                  <div class="flex-bd">
                    <el-input v-model="cerPhoneform.phone"></el-input>
                  </div>
                </div>
                <div class="cer-flex">
                  <div class="flex-bd">
                    <el-input v-model="cerPhoneform.code"></el-input>
                  </div>
                  <el-button v-show="show" @click="getCode" class="btn-code"
                    >发送验证码</el-button
                  >
                  <el-button v-show="!show" class="btn-code btn-diable"
                    >发送验证码</el-button
                  >
                  <div v-show="!show" class="tip-span">{{ count }} s</div>
                </div>
                <div style="text-align: center">
                  <button class="next" @click="bandEmailOrPhone">下一步</button>
                </div>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="邮箱验证" name="cemail">
              <el-form class="cer-form" ref="form" :model="cerEmailform">
                <div class="cer-flex">
                  <el-input
                    v-model="cerEmailform.email"
                    placeholder="请输入邮箱"
                  ></el-input>
                </div>
                <div class="cer-flex">
                  <div class="flex-bd">
                    <el-input v-model="cerEmailform.code"></el-input>
                  </div>
                  <el-button v-show="show2" @click="getCode2" class="btn-code"
                    >发送验证码</el-button
                  >
                  <el-button v-show="!show2" class="btn-code btn-diable"
                    >发送验证码</el-button
                  >
                  <div v-show="!show2" class="tip-span">{{ count2 }} s</div>
                </div>
                <div style="text-align: center">
                  <button class="next" @click="bandEmailOrPhone">下一步</button>
                </div>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  initAlipayCertification,
  queryCertResult,
  checkHavePhoneOrEmail,
  sendVerificationCode,
  bandEmailOrPhone,
} from "../../../service/index";
import { getIn } from "../../../utils/index";
import { Loading } from "element-ui";
import { mapState } from "vuex";

let timer = 0;
export default {
  computed: mapState(["userInfo"]),
  created() {
    // console.log(this.$store.state.userInfo,2121)
    this.shStatus = this.$store.state.userInfo.status.value;
  },
  data() {
    return {
      ways: [
        {
          icon: require("@/assets/img/alipay.png"),
          checked: true,
        },
        {
          icon: require("@/assets/img/just-logo.png"),
          checked: false,
        },
      ],
      agreetext: "同意CSON官方用户协议认证",
      form: {
        name: "",
        sid: "",
      },
      checked: false,
      dialogFormVisible: false,
      stepone: true,
      steptwo: false,
      stepthree: false,
      certImage: "",
      isLoading: false,
      shStatus: "",
      // 6.17
      cerVisible: false,
      activeName: "cphone",
      cerPhoneform: {
        phone: "",
        code: "",
      },
      cerEmailform: {
        email: "",
        code: "",
      },
      show: true,
      count: "",
      timer: null,
      show2: true,
      count2: "",
      timer2: null,
      countrys: [
        {
          value: "中国",
          label: "+86",
          val: "86",
        },
        {
          value: "美国 & 加拿大",
          label: "+1",
          val: "1",
        },
        {
          value: "中国香港",
          label: "+852",
          val: "852",
        },
        {
          value: "中国台湾",
          label: "+886",
          val: "886",
        },
        {
          value: "日本",
          label: "+81",
          val: "81",
        },
        {
          value: "韩国",
          label: "+82",
          val: "82",
        },
      ],
      countrysvalue: "86",
    };
  },
  watch: {
    "$store.state.userInfo.status.value"(newVal, oldVal) {
      this.shStatus = newVal;
    },
  },
  // async created() {
  //     this.onQueryCertResult({isQuery: true});
  // },
  mounted() {},
  methods: {
    onBack() {
      window.history.back();
      this.showSuccessDialog = false;
    },

    async onInitAlipayCertification(e) {
      e.preventDefault();
      const { name, sid } = this.form;
      console.log("name=" + name);
      console.log("sid=" + sid);
      if (!name) {
        this.$message.closeAll();
        this.$message.error("请输入您的姓名");
        return;
      }
      if (!name.replace(/[^\u4E00-\u9FA5]/g, "")) {
        this.$message.closeAll();
        this.$message.error("姓名必须输入中文");
        return;
      }

      if (!sid) {
        this.$message.closeAll();
        this.$message.error("请输入您的身份证号");
        return;
      }
      if (!sid.replace(/[^\dXx]/g, "")) {
        this.$message.closeAll();
        this.$message.error("请输入正确身份证号");
        return;
      }
      let captchaId = "2060393098";
      const captcha = new TencentCaptcha(captchaId, async (res) => {
        if (res.ret === 0) {
          const result = await initAlipayCertification({
            certName: name,
            certNo: sid,
          });
          const certImage = getIn(result, ["result"]);
          if (certImage && typeof certImage === "string") {
            this.certImage = certImage;
            this.stepone = false;
            this.steptwo = true;
            this.stepthree = false;
            this.onQueryCertResult();
          } else {
            this.$message.error(
              getIn(result, ["message"]) || "系统繁忙，请稍后再试"
            );
          }
          console.log("===", result);
        }
      });
      captcha.show();
    },
    change(index) {
      this.ways.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    },
    submit() {
      if (!this.checked) {
        this.$message.warning("请先勾选同意CSON官方用户协议认证");
        return;
      }
      // checkHavePhoneOrEmail().then(res => {
      //   if (res.success) {
      //     if (!res.result) {
      //       this.cerVisible = true;
      //     } else {
      //       if (this.ways[0].checked) {
      //         this.dialogFormVisible = true;
      //       } else {
      //         this.$router.push({path: "/center/csoncertificate"});
      //       }
      //     }
      //   }
      // });
      if (this.ways[0].checked) {
        this.dialogFormVisible = true;
      } else {
        this.$router.push({ path: "/center/csoncertificate" });
      }
    },
    async onQueryCertResult({ isQuery = false } = {}) {
      // const loadingInstance = Loading.service({
      //   fullscreen: true,
      //   lock: true,
      //   text: "加载中...",
      // });
      var timer = setInterval(async () => {
        const result = await queryCertResult();
        // 0 【审核失败/未提交】  1【待审核】 2【审核通过】 3【机器审核通过】
        if (result && result.code === 200 && result.result) {
          const value = Number(getIn(result, ["result", "value"]));
          if (!isQuery) {
            if (value === 2 || value === 3) {
              // loadingInstance.close();
              this.stepone = false;
              this.steptwo = false;
              this.stepthree = true;
              clearInterval(timer);
            } else {
              // const msg =
              //   value === 3 ? "您还未提交实名认证" : "系统繁忙，请稍后再试";
              // this.$message.error(msg);
              // const msg =
              //     value === 3 ? "您还未提交实名认证" : "请使用支付宝扫码认证";
              // this.$message.error(msg);
            }
          } else {
            this.shStatus = value;
          }
        } else {
          !isQuery &&
            this.$message.error(
              getIn(result, ["message"]) || "系统繁忙，请稍后再试"
            );
        }
      }, 1000);

      // console.log("===", result);
    },
    done() {
      this.stepone = false;
      this.steptwo = false;
      this.stepthree = false;
      this.dialogFormVisible = false;
      this.shStatus = 1;
      this.$store.dispatch("queryUserInfo").then((res) => {
        this.$forceUpdate();
      });
    },
    bandEmailOrPhone() {
      let phoneOrEmail = "";
      let code = "";
      if (this.activeName == "cphone") {
        phoneOrEmail = this.countrysvalue + this.cerPhoneform.phone;
        code = this.cerPhoneform.code;
      } else {
        phoneOrEmail = this.cerEmailform.email;
        code = this.cerEmailform.code;
      }
      bandEmailOrPhone(phoneOrEmail, code).then((res) => {
        if (res.success && res.result) {
          this.$message.success("绑定成功");
          this.cerVisible = false;
          this.submit();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    sendVerificationCode() {
      let phoneOrEmail = "";
      if (this.activeName == "cphone") {
        phoneOrEmail = this.countrysvalue + this.cerPhoneform.phone;
      } else {
        phoneOrEmail = this.cerEmailform.email;
      }
      sendVerificationCode(phoneOrEmail).then((res) => {
        if (res.success) {
          this.$message.success("发送成功");
        }
      });
    },
    // 6.17
    getCode() {
      this.sendVerificationCode();
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    getCode2() {
      this.sendVerificationCode();
      const TIME_COUNT = 60;
      if (!this.timer2) {
        this.count2 = TIME_COUNT;
        this.show2 = false;
        this.timer2 = setInterval(() => {
          if (this.count2 > 0 && this.count2 <= TIME_COUNT) {
            this.count2--;
          } else {
            this.show2 = true;
            clearInterval(this.timer2);
            this.timer2 = null;
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.sh-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sh-status > img {
  width: 36px;
  height: 36px;
  margin-top: 105px;
}

.sh-status > p {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 21px;
  margin-top: 15px;
}

.sh-status > div {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 21px;
  margin-top: 12px;
}

.sh-status > div > span {
  color: #0073ff;
}

/* 6.17 */
.cert-dialog {
  position: fixed;
  width: 590px;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.cer-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.flex-bd {
  flex: 1;
}

.cer-title {
  padding: 25px 0;
  text-align: center;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
}

.cer-form {
  padding: 15px 0;
}

.btn-code {
  width: 100px;
  height: 32px;
  padding: 0;
  text-align: center;
  border: 0;
  margin-left: 15px;
  color: #34495e;
  background: rgba(136, 148, 171, 0.2);
  border-radius: 2px;
}

.btn-diable {
  opacity: 0.5;
  cursor: auto;
}

.tip-span {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
  margin-left: 15px;
}

.cer-select {
  width: 100px;
}

.cer-select ::v-deep .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  background: rgba(235, 236, 241, 0.43);
  border-radius: 4px 0 0 4px;
}

.cer-select ::v-deep + .flex-bd .el-input__inner {
  border-radius: 0 4px 4px 0;
  border-left: 0;
}

.select-row {
  display: block;
}

.select-row .label {
  display: inline-block;
  width: 60px;
}
</style>
<style>
/* 6.17 */
.submit-con .el-tabs__header {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.submit-con .el-tabs__item {
  width: 50%;
  padding: 0;
}

.submit-con .el-tabs__nav {
  width: 100%;
}

.submit-con .el-tabs__item:hover {
  color: #fa6400;
}

.submit-con .el-tabs__item.is-active {
  color: #fa6400;
}

.submit-con .el-tabs__active-bar {
  background-color: #fa6400;
}

.cer-flex .el-input__inner {
  height: 36px;
}

.el-select-dropdown__item.selected {
  color: #fa6400;
}
</style>